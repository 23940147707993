export const onRouteUpdate = ({ location }) => {
    startup()

    document.querySelector('meta[name="generator"]') &&
        document.querySelector('meta[name="generator"]').remove()

    location.hash !== '' &&
        setTimeout(() => {
            let hashVal = location.hash.replace('#', '')
            let el = document.getElementById(hashVal)

            window.scrollTo({ top: el.offsetTop })
        }, 50)

}

// Startup checks and functions
const startup = () => {
    const tabsCheck = document.querySelectorAll('.elementor-tabs').length
    const vvTabsCheck = document.querySelectorAll('.vv-tabs').length
    const videoCheck = document
        .querySelectorAll('[data-widget_type="video.default"]').length
    const formIframes = document.querySelectorAll(
        'iframe[src*="info.grm"]:not(.grm-pardot-form)'
    )
    const stickyCheck = document.querySelectorAll('[data-settings*="sticky"]')
        .length
    const elementorTocCheck = document
        .querySelectorAll('.elementor-widget-table-of-contents').length

    const searchPageCheck = document
        .querySelectorAll('[data-elementor-id="23924"]').length
    const searchButtonCheck = document.querySelectorAll('.grm-search svg')
        .length
    const hamburgerCheck = document
        .querySelectorAll('[for="hamburger-menu-icon"]').length
    const vvMobileMenuCheck = document
        .querySelectorAll('.elementskit-menu-toggler').length
    const partnerSliderCheck = document
        .querySelectorAll('.elementor-image-carousel').length

    const megaMenuCheck = document.getElementById('ekit-megamenu-main-menu')

    if ( megaMenuCheck ) {
        megaMenuFix()

        document.getElementById('ekit-megamenu-main-menu')
            .style.pointerEvents = 'none'

        setTimeout(() => {
            document.getElementById('ekit-megamenu-main-menu')
                .style.pointerEvents = ''
        }
        , 1000)

        document.querySelectorAll('.menu-bottom').forEach((el) => {
            el.addEventListener('click', closeMegaMenu);
        })

        document.querySelectorAll('.elementskit-megamenu-panel a')
        .forEach((el) => {
            el.addEventListener('click', closeMegaMenu);
        })
       
    }
        
    if ( tabsCheck > 0 ) {
        initTabs()
        tabsOnClick()
    }

    if ( vvTabsCheck > 0 ) {
        if ( document.querySelectorAll('.vv-tabs .e-active').length === 0 )
            initVvTabs()

        if ( document.querySelectorAll('.e-n-tabs.e-activated').length === 0 ) {
            document.querySelector('.e-n-tabs').classList
                .add('e-activated')
            document.querySelector('.e-n-tabs').setAttribute('data-touch-mode', false)
        }
        
        vvTabsOnClick()
    }

    if (formIframes.length > 0) formIframeSize()
    if (videoCheck > 0) videoEmbedInit()
    if (elementorTocCheck > 0) 
        elementorTocRenderList(elementorTocCheck)

    if (stickyCheck > 0) elementorStickyFx(stickyCheck)

    renderAnimations()
    smoothScrollTo()
    webFlipEvent()

    if (searchPageCheck > 0) loadSiteSearch()

    if (searchButtonCheck > 0)
        document.querySelector('.grm-search svg')
            .addEventListener('click', loadSiteSearch)

    if (hamburgerCheck > 0) hamburgerChange()
    if (vvMobileMenuCheck > 0) vvMobileMenu()

    if (partnerSliderCheck> 0) partnerSliderInit()
}

const partnerSliderInit = () => {
    let prevButton = document.querySelector('.elementor-swiper-button-prev')
    let nextButton = document.querySelector('.elementor-swiper-button-next')
    let sliderWrap = document.querySelector('.elementor-widget-image-carousel')
    let fullSlide  = document.querySelector('.elementor-image-carousel')
    
    let allSlides  = document.querySelectorAll('.swiper-slide')

    // allSlides.forEach(slide => {
    //     let widthDiv = ( document.body.clientWidth > 1024  ) ? 
    //         5 : 3
    //     let calcWidth = document.body.clientWidth / widthDiv
        
    //     slide.querySelector('figure').style.width = calcWidth+'px' 

    // })

    const style = document.createElement('style');
    style.innerHTML = `
        body .elementor .elementor-widget
        :not(.elementor-widget-text-editor)
        :not(.elementor-widget-theme-post-content) figure {
            margin:0 auto;
            width:75%;
        }
        body .elementor-image-carousel::-webkit-scrollbar {
            display: none;
        }
        body .elementor-image-carousel {
            overflow-x: scroll;
            -ms-overflow-style: none; 
            scrollbar-width: none;
        }
        `
    document.head.appendChild(style)

    cloneNodesFn(allSlides, fullSlide)
   
    const mainLoopFn = () => {
        let scrollOffset    = fullSlide.scrollLeft
        let fullSlideW      = fullSlide.offsetWidth 
        let newOffset       = ( scrollOffset >= 2*fullSlideW ) ? 0 : scrollOffset+1

        fullSlide.scrollLeft = newOffset
    }

    let mainLoop = setInterval(mainLoopFn, 5)

    sliderWrap.addEventListener('mouseenter', () => {
        clearInterval(mainLoop)
    })

    sliderWrap.addEventListener('mouseleave', () => {
        if ( !fullSlide.getAttribute('data-clicked') )
            mainLoop = setInterval(mainLoopFn, 5)
    })

    prevButton.addEventListener('click', () => {
        partnerSlider(0, 5000, 'ease-in-out')
        fullSlide.setAttribute('data-clicked', 1)
    })

    nextButton.addEventListener('click', () => {
        partnerSlider(1, 5000, 'ease-in-out')
        fullSlide.setAttribute('data-clicked', 1)
    })
}

const closeMegaMenu = (e) => {
    let parent = e.target.closest('.elementskit-megamenu-panel')
    
    if ( !parent ) 
        return; // Exit if parent not found
    
    parent.style.display = 'none'
    
    setTimeout(() => {
        parent.style.display = 'block'
    }, 500);
}

const partnerSlider = ( dir ) => {
    let fullSlide        = document.querySelector('.elementor-image-carousel')
    let scrollOffsetInit = fullSlide.scrollLeft
    let fullSlideW       = fullSlide.offsetWidth
    let slideWidth       = document.querySelector('.swiper-slide').offsetWidth

    const animationFn  = () => {
        let scrollOffset = fullSlide.scrollLeft
        let newOffset    = ( dir ) ? scrollOffset+10 : scrollOffset-10
        let posFix       = scrollOffsetInit%slideWidth

        posFix = ( posFix < 20 || posFix > slideWidth-20 ) ? 0 : posFix

        let scrollTo     = ( dir ) ? 
            scrollOffsetInit + (fullSlideW - posFix) : 
            scrollOffsetInit - (fullSlideW - posFix)

        fullSlide.scrollLeft = newOffset

        if ( newOffset <= 0 ) {
            clearInterval(animation)

            fullSlide.scrollLeft = 2*fullSlideW
        }

        if ( newOffset >= 3*fullSlideW ) {
            clearInterval(animation)
            
            fullSlide.scrollLeft = fullSlideW
        }

        if ( (newOffset >= scrollTo && dir) ||
            (newOffset <= scrollTo && !dir) )
                clearInterval(animation)

    } 

    let animation = setInterval(animationFn, 5)
}

const cloneNodesFn = (nodes, parent) => {
    nodes.forEach(node => {
        let clone = node.cloneNode(true)

        clone.classList.add('clone')

        parent.appendChild(clone)
    });
} 

const vvMobileMenu = () => {
    let mobMenuToggles = 
        document.querySelectorAll('.elementskit-menu-toggler')
    let mobSubMenuToggles = 
        document.querySelectorAll('.ekit-menu-nav-link')

    mobMenuToggles.forEach(toggle => {
        toggle.addEventListener('click', vvHamburgerAction)

    })

    mobSubMenuToggles.forEach(toggle => {
        toggle.addEventListener('click', (e) => {
            e.preventDefault()

            toggle.closest('li')
                .querySelector('.elementskit-megamenu-panel').classList
                .toggle('elementskit-dropdown-open')
        })
    })
}

const vvHamburgerAction = () => {
    let megaMenu = document.getElementById('ekit-megamenu-main-menu')
    let menuOverlay = document.querySelector('.elementskit-menu-overlay')

    megaMenu.classList.toggle('active')
    menuOverlay.classList.toggle('active')
}

const megaMenuFix = () => {
    let globHeader = document
        .getElementById('global-header')
    let megaMenus = document
        .querySelectorAll('.elementskit-megamenu-panel')
    let blurredSections = document
        .getElementById('ekit-megamenu-main-menu')
        .querySelectorAll('.menu-bottom')
    let blurredTimeout

    blurredSections.forEach(blurredSection => {
        blurredSection.addEventListener('mouseenter', () => {
                blurredTimeout = setTimeout(() => {
                    blurredSection.style.display = 'none'
                    setTimeout(() => {
                        blurredSection.style.display = 'block'
                    }, 500);
                },
                5000)
            }
        )

        blurredSection.addEventListener('mouseleave', () => {
            clearTimeout(blurredTimeout)
        })
    })

    if ( globHeader ) {
        globHeader.addEventListener('mouseenter', (event) => {
            setTimeout(() => {
                globHeader.querySelector('ul').style.pointerEvents = ''
                
                megaMenus.forEach((megaMenu) => {
                    let offsetLeft = 
                        parseInt(
                            megaMenu.getBoundingClientRect().left
                        )
                    
                    if ( offsetLeft > 50 )
                        megaMenu.style.left = '-'+ offsetLeft + 'px'
                })
            }, 350)
        });
    }
}

const hamburgerChange = () => {
    let hamburger = document.querySelector('[for="hamburger-menu-icon"]')
    let theInput = document.querySelector('.hamburger-menu-icon')
    let innerToggles = document
        .querySelectorAll('.grm-mega-menu > li > [for^="grm-mmtog-"]')

    document.querySelector('body').style.overflow = ''
    
    hamburger.addEventListener('click', () => {
        if ( theInput.checked ) {
            hamburger.classList.remove('open')

            document.querySelector('body').style.overflow = ''

        } else {
            hamburger.classList.add('open')

            document.querySelector('body').style.overflow = 'hidden'
        }
    })

    innerToggles.forEach(toggle => {
        toggle.addEventListener('click', (e) => {
            e.preventDefault()

            let toBeChecked = toggle.getAttribute('for')
            let check = document.getElementById(toBeChecked).checked

            innerToggles.forEach(tog => {
                let target = tog.getAttribute('for')

                if ( toBeChecked !== target )
                    document.getElementById(target).checked = false
            })

            document.getElementById(toBeChecked).checked = ( check ) ? 
                false : true

        })
    })
}

const loadSiteSearch = () => {
    const scriptTag = document.createElement("script")
    scriptTag.src = "https://js.sitesearch360.com/plugin/bundle/3248.js"
    scriptTag.async = true
    document.body.appendChild(scriptTag)
}

const renderAnimations = () => {
    const itemParentSettings = document
        .querySelectorAll('[data-settings]')

    itemParentSettings.forEach((item) => {
        let settings = JSON.parse(item.getAttribute('data-settings'))

        let transStepX = ( settings.motion_fx_translateX_speed ) ? 
            settings.motion_fx_translateX_speed.size * -1 : 0
        let transStepY = ( settings.motion_fx_translateY_speed ) ?
            settings.motion_fx_translateY_speed.size * -1 : 0
        let transStepR = (settings.motion_fx_rotateZ_speed) ?
            settings.motion_fx_rotateZ_speed.size : 0

        if ( settings.motion_fx_range )
            item.setAttribute('data-range', settings.motion_fx_range)
            
        if ( settings.motion_fx_translateX_affectedRange ) {
            let arStart =
                settings.motion_fx_translateX_affectedRange.sizes.start
            let arEnd =
                settings.motion_fx_translateX_affectedRange.sizes.end

            item.setAttribute('data-ar-start', arStart)
            item.setAttribute('data-ar-end', arEnd)
        }

        if ( settings.motion_fx_translateX_speed ) {
            let speedSize = settings.motion_fx_translateX_speed.size

            item.setAttribute('data-speed', speedSize)
        }

        if ( settings.motion_fx_translateX_direction &&
            settings.motion_fx_translateX_direction === 'negative' ) 
                transStepX = transStepX * -1

        if ( settings.motion_fx_translateY_direction &&
            settings.motion_fx_translateY_direction === 'negative' )
                transStepY = transStepY * -1

        if ( settings.motion_fx_translateX_direction &&
            settings.motion_fx_translateX_direction === 'negative' )
                transStepR = transStepR * -1

        if ( transStepX || transStepY || transStepR ) 
            item.classList.add('parallax-item')
        if ( transStepX ) item.setAttribute('data-trans-x', transStepX)
        if ( transStepY ) item.setAttribute('data-trans-y', transStepY)
        if ( transStepR ) item.setAttribute('data-trans-r', transStepR)
    
        if ( settings._animation ) {
            item.classList.add(settings._animation)
            item.setAttribute('data-anim-delay', settings._animation_delay)
        }

        item.classList.add('elementor-animation')

    })

    window.onscroll = () => {
        const items = document.querySelectorAll('.elementor-animation')
        let windowScroll = window.scrollY / 10
        let viewpBottom = window.scrollY + window.innerHeight

        items.forEach((item) => {
            let itemBottomOffset = item.getBoundingClientRect().top +
                item.getBoundingClientRect().height +
                document.documentElement.scrollTop
            // let range = item.getAttribute('data-range')
            
            if ( itemBottomOffset > window.scrollY ) {
                if ( item.classList.contains('parallax-item') ) {
                    let transX = 
                        windowScroll * item.getAttribute('data-trans-x')
                    let transY = 
                        windowScroll * item.getAttribute('data-trans-y')
                    let transR = 
                        windowScroll * item.getAttribute('data-trans-r')
            
                    if ( !item.getAttribute('data-ar-end') ) {
                        
                        item.style.transform =
                            'translate(' + transX + 'px,' + transY + 'px) '+
                            'rotate('+ transR +'deg)'
                    }
                }
            }

            if ( itemBottomOffset < viewpBottom ) {
                if ( item.getAttribute('data-ar-end') ) {
                    let speed = item.getAttribute('data-speed')
                    let end = item.getAttribute('data-ar-end')
                    let limit = (window.innerHeight/100) * end
                    let max = speed * end
                    let calculated = 
                        (itemBottomOffset + limit) - viewpBottom 


                    if ( !item.style.transition )
                        item.style.transition = 'transform .1s linear'

                    if ( calculated > 0 ) {
                        let change = parseInt(calculated/end) * 10

                        item.style.transform = 
                            'translateX(-'+ (max/100) * change +'px)'
                    } 
                }

                if ( !item.getAttribute('data-anim-played') ) {
                    item.setAttribute('data-anim-played', 1)
                    
                    if ( item.classList.contains('slideInLeft') ) {
                        item.style.transform = 'translateX(-100%)' 
                    }   
                    
                    if ( item.classList.contains('zoomIn') ) {
                        item.style.transform = 'scale(0)'
                    }
                    
                    if (item.classList.contains('fadeIn')) {
                        item.style.opacity = '0'
                    }
                    
                    if ( item.getAttribute('data-anim-delay') ) {
                        let msTimeout = 
                            parseInt(item.getAttribute('data-anim-delay'))
    
                        setTimeout(() => {
                            item.style = ''
                            item.style.visibility = 'visible'
                        }, msTimeout)
                    }
                }
            }
        });
    }
}

const smoothScrollTo = () => {
    let items = document.querySelectorAll('a[href^="#"]')

    items.forEach((item) => {
        item.addEventListener('click', (e) => {
            e.preventDefault()

            const href = item.getAttribute("href").toString()
            const target = document.querySelector(href)
            const offsetTop = target.getBoundingClientRect().top + 
                document.documentElement.scrollTop - 60
            let focusedItem = document.querySelectorAll('.toc-focused')

            focusedItem &&
                focusedItem.forEach((active) => {
                    active.classList.remove('toc-focused')
                })

            item.classList.add('toc-focused')
            target.classList.add('toc-focused')

            window.scrollTo({
                top: offsetTop,
                behavior: "smooth"
            })

        })
    })
}

const elementorTocRenderList = () => {
    const elementorToc = document
        .querySelectorAll('.elementor-widget-table-of-contents')

    if ( elementorToc.length ) {
        elementorToc.forEach((toc) => {
            let tocBody = toc.querySelector('.elementor-toc__body')
            let h3Headings = document.querySelectorAll('h3')
            let itemList = ''
            
            h3Headings.forEach((heading, i) => {
                let anchor = 'anchor-'+ i

                heading.closest('.elementor-section').id = anchor

                itemList += 
                    '<li class="elementor-toc__list-item">'+
                        '<div class="elementor-toc__list-item-text-wrapper">'+
                            '<a href="#' + anchor +'" '+
                                    'class="elementor-toc__list-item-text '+
                                        'elementor-toc__top-level">'+
                                heading.textContent +
                    '</li>'
            })

            tocBody.innerHTML = 
                '<ol class="elementor-toc__list-wrapper">'+ 
                    itemList +
                '</ol>'
        })
    }
}

const elementorStickyFx = () => {
    const stickyItems = document.querySelectorAll('[data-settings*="sticky"]')
    const contactOffset = document.getElementById('global-footer')
        .getBoundingClientRect().top + document.documentElement.scrollTop
    const stikyCancelBottom = contactOffset - window.innerHeight

    stickyItems.forEach((item) => {
        let iClasses = item.classList
        let iOffsetTop = item.getAttribute('data-offset-top') 
        let settings = JSON.parse(item.getAttribute('data-settings'))
        let windowScroll = window.scrollY
        let activeStatus = iClasses.contains('sticky-active')
        let stickyOffset = settings.sticky_offset | 0
        let stickyOTP = stickyOffset

        if ( item.querySelector('.elementor-toc__body') )
            stickyOTP = stickyOTP + item.querySelector('.elementor-toc__body')
                .style.paddingTop

        if ( !iClasses.contains('sticky') ) {
            item.style.width = '100vw'
            item.setAttribute('data-offset-top', item.getBoundingClientRect()
                .top + document.documentElement.scrollTop)

            item.style.top = stickyOffset
            item.style.position = 'fixed'
            item.style.zIndex = 9
            iClasses.add('sticky')
        } 

        if ( iClasses.contains('sticky') && !item.closest('header')) {
            if ( settings.sticky === "top"  ) {
                if ( windowScroll < stikyCancelBottom ) {
                    item.style.top = 0
    
                    if ( (windowScroll > iOffsetTop 
                        && windowScroll < contactOffset) 
                        && !activeStatus ) {
                            item.style.transform = 
                                'translateY('+ stickyOTP + 'px)'
                            iClasses.add('sticky-active')
                    } 
        
                    if ( iOffsetTop > windowScroll && activeStatus ) {
                        item.style.transform = ''
                        iClasses.remove('sticky-active')
                    }
    
                } else {
                    item.style.top = (stikyCancelBottom - windowScroll) +'px'
    
                }
    
            } 
        }

    })
}

const webFlipEvent = () => {
    let staticFlipElement = document.querySelectorAll('.static-flip')

    if ( staticFlipElement.length > 0 ) {
        const webpFlip = {
            ids: [
                'analytics-slider',
                'workflow-slider',
                'iforms-slider',
            ],
            switchSlide(id, visible) {
                let parent = document.getElementById(id)
                let childImg = parent
                    .querySelector('.elementor-widget-container > img')

                if ( !childImg ) return false
                
                let src = childImg.getAttribute('src')
                let srcset = childImg.getAttribute('srcset')
                let dataSrc = parent.getAttribute('data-src')
                let dataSrcset = parent.getAttribute('data-srcset')

                parent.setAttribute('data-src', src)
                parent.setAttribute('data-srcset', srcset)

                childImg.src = dataSrc
                childImg.srcset = dataSrcset

                this.setStatus(id, visible)
            },
            getIds() {
                return this.ids
            },
            isInSight(id) {
                let element = document.getElementById(id)

                if ( element ) {
                    let hT = element.getBoundingClientRect().top +
                        document.documentElement.scrollTop
                    let hH = element.offsetHeight
                    let wH = window.innerHeight
                    let wS = window.scrollY
    
                    return wS > (hT + hH - wH)
                }
            },
            setStatus(id, visible) {
                let element = document.getElementById(id)

                if ( visible && !element.classList.contains('playing') ) {
                    element.classList.add('playing')

                } else {
                    element.classList.remove('playing')

                }
            },
            isPlaying(id) {
                let target = document.getElementById(id)

                return (target) ? target.classList.contains('playing') : false

            }
        }

        document.onscroll = () => {
            let ids = webpFlip.getIds()

            for ( let id of ids ) {
                if ( webpFlip.isInSight(id) ) {
                    for ( let toOff of ids ) {
                        if ( toOff !== id && webpFlip.isPlaying(toOff) )
                        webpFlip.switchSlide(toOff, false);

                    }

                    !webpFlip.isPlaying(id) &&
                        webpFlip.switchSlide(id, true)

                    return false
                }
            }
        }
    }
}

const formIframeSize = () => {
    if ( window.hasOwnProperty('rm') || typeof window.rm === 'function')
        return;

    window.rm = function (event) {  
        const pddomain = "info.grmdocumentmanagement.com";
        const selector = 'iframe[src*="' + pddomain + '"]';
        let regexp = new RegExp(pddomain);
        let isResize = event.data.hasOwnProperty('msg') &&
                        event.data.msg === "resize";

        if ( regexp.test(event.origin) && isResize ) {
            let matches = document.querySelectorAll( selector );

            for ( let i=0; i < matches.length; i++ ) {
                if ( matches[i].contentWindow === event.source ){
                    if ( !isNaN( event.data.height ) ) {
                        matches[i].height = Number( event.data.height );

                        return 1;
                    }
                }
            }
        }
    }

    window.addEventListener("message", window.rm, false);
}

const initVvTabs = () => {
   
    document.querySelectorAll('.e-n-tabs-heading > button')[0].classList
        .add('e-active')
    document.querySelectorAll('.e-n-tabs-content > .e-con')[0].classList
        .add('e-active')
    document.querySelectorAll('.e-n-tabs-content > .elementor-widget-heading')[0].classList
        .add('e-active')
}

const vvTabsOnClick = () => {
    let tabSelect = document
        .querySelectorAll('.e-n-tabs-heading > button, '+
            '.e-n-tabs-content > div')


    tabSelect.forEach(function (v, i) {
        let _this = tabSelect[i]

        _this.addEventListener('click', function (e) {
            let tabID = _this.getAttribute('data-tab-index')
            let tabTitles = document
                .querySelectorAll('.e-n-tabs [data-tab-index="'+ tabID +'"]')
            let active = document.querySelectorAll('.e-n-tabs .e-active')
            let activeTabContent = document
                    .querySelector('.e-n-tabs-content '+
                        '> .e-con.e-active')

            console.log(active)

            active.forEach((v, i) => {
                v.classList.remove('e-active')
            })
            activeTabContent.classList.remove('e-active')

            tabTitles.forEach((v, i) => {
                v.classList.add('e-active')
            })
            document.querySelector(
                '.e-n-tabs-content > [data-tab-index="' + tabID + '"]'
            ).classList.add('e-active')

        })
    })
}

const initTabs = () => {
    document.querySelectorAll('.elementor-tab-title')[0].classList
        .add('elementor-active')
    document.querySelectorAll('[data-tab="1"][role="tabpanel"]')[0].style
        .display = 'block'
}

const tabsOnClick = () => {
    let tabSelect = document.querySelectorAll('[role="tablist"] [role="tab"]')

    tabSelect.forEach(function (v, i) {
        let _this = tabSelect[i]

        _this.addEventListener('click', function (e) {
            let tab = _this.getAttribute('data-tab'),
                activeTab = document
                    .querySelectorAll('[role="tablist"] .elementor-active')[0],
                activeTabContent = document
                    .querySelectorAll('[role="tabpanel"][style]')[0]

            activeTab.classList.remove('elementor-active')
            activeTabContent.removeAttribute('style')

            _this.classList.add('elementor-active')
            document.querySelectorAll(
                '[data-tab="' + tab + '"][role="tabpanel"]'
            )[0].style.display = 'block'

        })
    })
}

const videoEmbedInit = () => {
    let videos = document.querySelectorAll('[data-widget_type="video.default"]')

    videos.forEach(function (v, i) {
        let _this = videos[i]
        let videoData = JSON.parse(_this.getAttribute('data-settings'))

        if ( videoData.youtube_url ) {
            let embedUrl = videoData.youtube_url.replace('watch?v=', 'embed/')
            let vidIframe = _this.querySelector('iframe')
    
            if ( !vidIframe ) { 
                vidIframe = _this.querySelector('.elementor-video')
                    .appendChild(document.createElement('iframe'))
    
                vidIframe.setAttribute('frameborder', 0)
                vidIframe.setAttribute('allowfullscreen', '1')
                vidIframe.setAttribute('width', '640')
                vidIframe.setAttribute('height', '360')
                vidIframe.setAttribute('allow',
                    'accelerometer; autoplay; clipboard-write;'+
                    ' encrypted-media; gyroscope; picture-in-picture;'+
                    ' web-share"', 'YouTube video player')
                vidIframe.classList.add('elementor-video')
                vidIframe.style.width = '640px'
                vidIframe.style.height = '360px'
            }
    
            vidIframe.setAttribute('src', embedUrl)
        }
    })
}
